import { render, staticRenderFns } from "./ReImBurseMent.vue?vue&type=template&id=60d7c328&scoped=true"
import script from "./ReImBurseMent.vue?vue&type=script&lang=js"
export * from "./ReImBurseMent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60d7c328",
  null
  
)

export default component.exports