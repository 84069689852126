<template>
  <v-container>
    <v-form ref="form" v-model="isFormValid">
      <v-row>
        <!-- MAIN_ID -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.MAIN_ID"
            label="主檔案 ID"
            :rules="[rules.required, rules.numeric]"
            maxlength="22"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_DT -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_DT"
            label="評估日期"
            :rules="[rules.required, rules.dateFormat]"
            maxlength="8"
            placeholder="yyyyMMdd"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_USER -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_USER"
            readonly
            label="評估人員"
            :rules="[rules.required]"
            maxlength="200"
            required
          ></v-text-field>
        </v-col>
        <v-col class="pa-0" cols="12"> <v-divider /></v-col>

        <!-- SHOPPING -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.SHOPPING"
            :items="shoppingOptions"
            label="購物"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- HOUSE_WORK -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.HOUSE_WORK"
            :items="houseWorkOptions"
            label="家務"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- FINANCE -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.FINANCE"
            :items="financeOptions"
            label="理財"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- FOOD -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.FOOD"
            :items="foodOptions"
            label="備食"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- TRAFFIC -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.TRAFFIC"
            :items="trafficOptions"
            label="交通"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- TELEPHONE -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.TELEPHONE"
            :items="telephoneOptions"
            label="電話"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- LAUNDRY -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.LAUNDRY"
            :items="laundryOptions"
            label="洗衣"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- TAKE_MED -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.TAKE_MED"
            :items="takeMedOptions"
            label="服藥"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="pa-2" justify="center"
        ><v-btn color="primary" @click="submitForm"> 送出表單 </v-btn></v-row
      >
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      isFormValid: false,
      form: {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        SHOPPING: '',
        HOUSE_WORK: '',
        FINANCE: '',
        FOOD: '',
        TRAFFIC: '',
        TELEPHONE: '',
        LAUNDRY: '',
        TAKE_MED: '',
      },
      rules: {
        required: (value) => !!value || '此欄位為必填項目',
        numeric: (value) => !isNaN(value) || '必須是數字',
        dateFormat: (value) => /\d{8}/.test(value) || '請輸入正確的日期格式 yyyyMMdd',
      },
      shoppingOptions: [
        { text: '能獨立完成購物', value: '5' },
        { text: '只能獨立購買簡單日常必需品', value: '10' },
        { text: '需他人陪同購物', value: '15' },
        { text: '完全不會獨立購物', value: '20' },
      ],
      houseWorkOptions: [
        { text: '能獨立處理較繁重家務，如搬動家具、清理廚房', value: '5' },
        { text: '能做較簡單的家事，如洗碗、鋪床、疊被', value: '10' },
        { text: '能做較簡單的家事，但不能達到可被接受的整潔程度', value: '15' },
        { text: '所有的家事都需要別人協助', value: '20' },
        { text: '完全不會處理家事', value: '25' },
      ],
      financeOptions: [
        { text: '可獨立處理財務(提存款、支付房租、帳單、給錢、找錢等)', value: '5' },
        { text: '可以處理日常的購買找錢，但需要別人協助與銀行往來或大宗買賣', value: '10' },
        { text: '完全不能處理錢財', value: '15' },
      ],
      foodOptions: [
        { text: '能獨立計畫、準備食材及佐料、烹煮和擺設一頓飯菜', value: '5' },
        { text: '如果準備好一切食材及佐料，會做一頓的飯菜', value: '10' },
        { text: '會將已做好的飯菜加熱', value: '15' },
        { text: '需要別人把飯菜煮好、擺好', value: '20' },
      ],
      trafficOptions: [
        { text: '能夠自己開車、騎車或自己搭乘大眾運輸工具', value: '5' },
        { text: '能夠自己搭乘計程車但不能搭乘大眾運輸工具', value: '10' },
        { text: '當有人陪同時，可搭乘大眾運輸工具', value: '15' },
        { text: '只能在有人協助或陪同時，可搭乘計程車或自用車', value: '20' },
        { text: '完全不能出門', value: '25' },
      ],
      telephoneOptions: [
        { text: '能獨立使用電話，含查電話簿、撥號等', value: '5' },
        { text: '僅能撥熟悉的電話號碼(少於5組的常用電話)', value: '10' },
        { text: '僅能接電話，但不能撥電話', value: '15' },
        { text: '完全不會使用電話', value: '20' },
      ],
      laundryOptions: [
        { text: '自己清洗所有衣物', value: '5' },
        { text: '只能清洗小件衣物，需協助晾曬衣物或洗滌厚重衣物', value: '10' },
        { text: '需完全協助，所有衣物需完全由別人協助洗及晾曬', value: '15' },
      ],
      takeMedOptions: [
        { text: '能自己負責在正確的時間服用正確的藥物', value: '5' },
        { text: '如果能事先將藥物的份量備妥，則可自行服用', value: '10' },
        { text: '不能自己負責服藥', value: '15' },
      ],
    }
  },
  methods: {
    clearForm() {
      this.form = {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        SHOPPING: '',
        HOUSE_WORK: '',
        FINANCE: '',
        FOOD: '',
        TRAFFIC: '',
        TELEPHONE: '',
        LAUNDRY: '',
        TAKE_MED: '',
      }
      this.$refs.form.resetValidation()
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        console.log(this.form)
        try {
          const response = await axios.post('/api/mohw_assessment/qc108', this.form)

          if (response.status === 200) {
            alert('資料成功上傳！')
            this.clearForm()
          } else {
            throw new Error('資料上傳失敗')
          }
        } catch (error) {
          console.log(`上傳失敗：${error.response?.data?.message || error.message}`)
        }
      }
    },
  },
}
</script>
