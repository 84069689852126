<template>
  <div class="pa-2 ma-auto">
    <v-row no-gutters justify="center" align="center" style="height: 100%">
      <v-col class="pa-2" cols="4">
        <v-btn
          class="font-weight-bold"
          :color="isGoingToInstitution ? '#25f7f7': '#f7f025'"
          width="100%"
          @click="toggleDirection"
        >
        點選切換路程：<span>{{ isGoingToInstitution ? "往機構" : "家屬送回" }}</span>
        </v-btn>
      </v-col>
      <v-col class="pa-2" cols="4">
        <v-select
          dense
          outlined
          placeholder="選擇機構"
          :items="institutionList"
          v-model="selectedInstitution"
          class="pa-2"
          hide-details=""
          @change="onInstitutionChange"
        ></v-select>
      </v-col>
      <v-btn-toggle
        v-model="toggle_exclusive"
        multiple
        style="width: 100%"
        background-color="#f2f2f2"
      >
        <v-row no-gutters justify="center">
          <v-col
            class="pa-2"
            cols="3"
            v-for="(role, index) in filteredCases"
            :key="index"
          >
            <v-btn
              :class="buttonClass(role.display, role.get_off_time)"
              active-class="select-button"
              x-large
              :value="role"
              width="100%"
            >
              <span class="font-weight-bold">{{ role.name }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-btn-toggle>
      <v-row
        style="height: 50%; width: 100vw"
        no-gutters
        justify="center"
        align="center"
      >
        <v-col cols="4">
          <v-btn
            class="font-weight-bold"
            color="#98D8AA"
            width="80%"
            @click="aboard(on)"
          >
            上車
          </v-btn>
          <v-spacer />
        </v-col>
        <v-col cols="4">
          <v-btn
            class="font-weight-bold"
            color="#F7D060"
            width="80%"
            @click="aboard(off)"
          >
            下車
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
    <v-snackbar centered v-model="snackbar" timeout="1500" :color="snackColor">
      <div class="font-weight-bold text-center" style="color: #f6f1f1">
        <span>{{ check_response }}</span>
      </div>
      <!-- <template v-slot:action="{ attrs }">
        <v-btn color="#AFD3E2" text v-bind="attrs" @click="snackbar = false">
          關閉
        </v-btn>
      </template> -->
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
export default {
  data() {
    return {
      check_response: "",
      names: "",
      case_numbers: [],
      toggle_exclusive: [],
      on: "get_on",
      off: "get_off",
      snackbar: false,
      snackColor: "",
      cases: [],
      thisDate: new Date(),
      isGoingToInstitution: true,

      // 篩選機構
      institutionList: [],
      selectedInstitution: null,
      filteredCases: [],
    };
  },
  methods: {
    async getList() {
      // console.log(this.formattedDate);
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/attendance/list_traffic?datetime=${this.formattedDate}&isGoingToInstitution=${this.isGoingToInstitution}`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        console.log(res.data);
        this.cases = res.data;
        this.filteredCases = this.cases;
        this.institutionList = [
          ...new Set(res.data.map((item) => item.institution)),
        ];
        this.institutionList.unshift("全部");
      } catch (error) {
        console.log(error);
      }
    },
    onInstitutionChange() {
      if (this.selectedInstitution === "全部") {
        this.filteredCases = this.cases;
      }
      else if (this.selectedInstitution) {
        this.filteredCases = this.cases.filter(
          (item) => item.institution === this.selectedInstitution
        );
      } else {
        this.filteredCases = this.cases;
      }
    },
    async aboard(state) {
      console.log(this.toggle_exclusive);
      let currentTime = moment().toISOString();
      console.log(currentTime);
      this.toggle_exclusive.forEach((element) => {
        this.case_numbers.push(element.case_num);
        this.names += element.name + ",";
      });
      console.log(this.case_numbers);
      if (state == "get_on") {
        this.check_response = this.names + "完成交通車簽到";
        this.snackColor = "#146C94";
        this.snackbar = true;
      } else if (state == "get_off") {
        this.check_response = this.names + "完成交通車簽退";
        this.snackColor = "#943c14";
        this.snackbar = true;
      }
      const data = {
        case_numbers: this.case_numbers,
        datetime: currentTime,
        state: state, // 上車；"get_off" 為下車
        isGoingToInstitution: this.isGoingToInstitution,
      };
      await axios
        .post("/api/attendance/get_on_off", data)
        .then((res) => {
          console.log(res.data);
          this.toggle_exclusive = [];
        })
        .catch((error) => {
          console.log("failed", error);
        });
      this.case_numbers = [];  // 清空個案避免重複操作
      this.names = [];
      this.cases = [];  // 清空個案讓 getList 得以全部取代
      this.getList();
    },
    buttonClass(display, off_time) {

      // 往機構。以上車
      if (display && this.isGoingToInstitution && off_time == null ){
          return {
            "go-onboard-button": true,
            button: true,
          }
      }

      // 往機構，已下車
      if (this.isGoingToInstitution && !display && off_time != null){
        return {
          "go-offboard-button": true,
          button: true,
        }
      }

      // 回家，上車
      if (!this.isGoingToInstitution && display && off_time == null){
        return {
          "return-onboard-button": true,
          button: true,
        }
      }

      // 回家，下車

      if (!this.isGoingToInstitution && !display && off_time != null){
        return {
          "return-offboard-button": true,
          button: true,
        }
      }
    },
    toggleDirection() {
      this.cases = [];
      this.isGoingToInstitution = !this.isGoingToInstitution;
      this.getList();
    },
  },
  computed: {
    formattedDate: {
      get() {
        const year = this.thisDate.getFullYear();
        const month = this.thisDate.getMonth() + 1;
        const day = this.thisDate.getDate();
        return `${year}-${month.toString().padStart(2, "0")}-${day
          .toString()
          .padStart(2, "0")}`;
      },
      set(newValue) {
        const [year, month, day] = newValue.split("-").map(Number);
        this.thisDate = new Date(year, month - 1, day);
      },
    },
  },
  mounted() {
    this.getList();
    console.log(this.cases);
  },
};
</script>
<style scoped>
.button {
  color: #321e1e;
}

.go-onboard-button {
  background: #1d8aef !important;
  color: white;
}
.go-offboard-button {
  background: #1d8aef !important;
  color: white;
  opacity: 0.7;
}
.return-onboard-button {
  background: #ed7732 !important;
  color: white;
}
.return-offboard-button {
  background: #ed7732 !important;
  color: white;
  opacity: 0.7;
}

/* select button 請維持在最下面，讓 btn 可以使用此 css 被覆蓋 */
.select-button {
  background: #cd1818 !important;
  color: white;
}
</style>
