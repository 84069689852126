<template>
  <v-container>
    <v-form ref="form" v-model="isFormValid">
      <v-row>
        <!-- MAIN_ID -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.MAIN_ID"
            label="主檔案 ID"
            :rules="[rules.required, rules.numeric]"
            maxlength="22"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_DT -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_DT"
            label="評估日期"
            :rules="[rules.required, rules.dateFormat]"
            maxlength="8"
            placeholder="yyyyMMdd"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_USER -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_USER"
            readonly
            label="評估人員"
            :rules="[rules.required]"
            maxlength="200"
            required
          ></v-text-field>
        </v-col>
        <v-col class="pa-0" cols="12"> <v-divider /></v-col>

        <!-- EAT -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.EAT"
            :items="eatOptions"
            label="進食"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- MOVE -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.MOVE"
            :items="moveOptions"
            label="移位"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- HEALTH -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.HEALTH"
            :items="healthOptions"
            label="衛生"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- TOILET -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.TOILET"
            :items="toiletOptions"
            label="如廁"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- BATH -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.BATH"
            :items="bathOptions"
            label="洗澡"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- WALK -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.WALK"
            :items="walkOptions"
            label="走動"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- STAIRS -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.STAIRS"
            :items="stairsOptions"
            label="樓梯"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- WEAR -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.WEAR"
            :items="wearOptions"
            label="穿脫"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- DEFECATE -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.DEFECATE"
            :items="defecateOptions"
            label="大便"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- PEE -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.PEE"
            :items="peeOptions"
            label="小便"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="pa-2" justify="center"
        ><v-btn color="primary" @click="submitForm"> 送出表單 </v-btn></v-row
      >
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      isFormValid: false,
      form: {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        EAT: '',
        MOVE: '',
        HEALTH: '',
        TOILET: '',
        BATH: '',
        WALK: '',
        STAIRS: '',
        WEAR: '',
        DEFECATE: '',
        PEE: '',
      },
      rules: {
        required: (value) => !!value || '此欄位為必填項目',
        numeric: (value) => !isNaN(value) || '必須是數字',
        dateFormat: (value) => /\d{8}/.test(value) || '請輸入正確的日期格式 yyyyMMdd',
      },
      eatOptions: [
        {
          text: '１０分 - 不需他人協助，在合理的時間(一小時)內，可自行用餐具取用眼前食物，或能自行取用穿脫進食輔具，將餐盤內食物吃完',
          value: '5',
        },
        {
          text: '５分 - 需他人協助取用或切好食物或穿脫進食輔具，但可自行吃飯',
          value: '10',
        },
        {
          text: '０分 - 無法自行取食',
          value: '15',
        },
      ],
      moveOptions: [
        {
          text: '１５分 - 可自行坐起，且由床移位至椅子或輪椅，使用輪椅的煞車及腳踏板，都不需要他人協助，且沒有安全上的顧慮',
          value: '5',
        },
        {
          text: '１０分 - 移位時需要少部分協助或提醒，且有安全上的顧慮',
          value: '10',
        },
        {
          text: '５分 - 可自行坐起，但移位至椅子或輪椅，需他人大部份的協助',
          value: '15',
        },
        {
          text: '０分 - 不能自行移位，完全需他人協助才能坐起或移位',
          value: '20',
        },
      ],
      healthOptions: [
        {
          text: '５分 - 可自行刷牙、洗臉、洗手、梳頭及刮鬍子',
          value: '5',
        },
        {
          text: '０分 - 他人協助才能完成上述盥洗項',
          value: '10',
        },
      ],
      toiletOptions: [
        {
          text: '１０分 - 可自行上下馬桶，穿脫衣褲且不弄髒衣物，自行取用 衛生紙擦拭清潔，且不需他人協助。如使用便盆(尿壺)，可自行取放並清洗乾淨',
          value: '5',
        },
        {
          text: '５分 - 需扶持使用馬桶或便盆(尿壺)，協助整理衣物或使用 衛生紙或協助清理便盆(尿壺)',
          value: '10',
        },
        {
          text: '０分 - 完全需要他人協助',
          value: '15',
        },
      ],
      bathOptions: [
        {
          text: '５分 - 可自行完成盆浴、淋浴或擦澡',
          value: '5',
        },
        {
          text: '０分 - 他人協助才能完成，或可自行完成，但執行困難或清潔度不佳',
          value: '10',
        },
      ],
      walkOptions: [
        {
          text: '１５分 - 使用或不使用輔具皆可獨立行走50公尺以上，無安全顧慮',
          value: '5',
        },
        {
          text: '１０分 - 需他人稍微扶持或口頭教導，才可行走50公尺以上',
          value: '10',
        },
        {
          text: '５分 - 雖無法行走，但可獨立操作輪椅或電動輪椅並可推行輪椅 50 公尺以上',
          value: '15',
        },
        {
          text: '０分 - 不能行走50公尺，且無法操縱輪椅，完全依賴他人',
          value: '20',
        },
      ],
      stairsOptions: [
        {
          text: '１０分 - 可自行上下樓梯',
          value: '5',
        },
        {
          text: '５分 - 需要他人稍微扶持或口頭指導',
          value: '10',
        },
        {
          text: '０分 - 完全無法上下樓梯',
          value: '15',
        },
      ],
      wearOptions: [
        {
          text: '１０分 - 可自行穿脫衣褲鞋襪，必要時使用輔具',
          value: '5',
        },
        {
          text: '５分 - 在別人幫助下，可自行完成一半以上動作',
          value: '10',
        },
        {
          text: '０分 - 需要他人完全幫忙',
          value: '15',
        },
      ],
      defecateOptions: [
        { text: '１０分 - 無失禁，需要時能自行使用塞劑、甘油球，不需他人協助', value: '5' },
        { text: '５分 - 偶而失禁(每週不超過一次)，或當便秘時需協助使用塞劑', value: '10' },
        { text: '０分 - 失禁(每週超過二次(含)以上)或當便秘時需要灌腸', value: '15' },
      ],
      peeOptions: [
        { text: '１０分 - 日夜皆無尿失禁，可完全自我控制', value: '5' },
        { text: '５分 - 偶而失禁(每週不超過一次)，使用尿布尿套時需他人幫忙', value: '10' },
        { text: '０分 - 失禁(每週超過二次(含)以上)或使用導尿管', value: '15' },
      ],
    }
  },
  methods: {
    clearForm() {
      this.form = {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        EAT: '',
        MOVE: '',
        HEALTH: '',
        TOILET: '',
        BATH: '',
        WALK: '',
        STAIRS: '',
        WEAR: '',
        DEFECATE: '',
        PEE: '',
      }
      this.$refs.form.resetValidation()
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        console.log(this.form)
        try {
          const response = await axios.post('/api/mohw_assessment/qc107', this.form)

          if (response.status === 200) {
            alert('資料成功上傳！')
            this.clearForm()
          } else {
            throw new Error('資料上傳失敗')
          }
        } catch (error) {
          console.log(`上傳失敗：${error.response?.data?.message || error.message}`)
        }
      }
    },
  },
}
</script>
