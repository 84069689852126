<template>
  <v-container>
    <v-form ref="form" v-model="isFormValid">
      <v-row>
        <!-- MAIN_ID -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.MAIN_ID"
            label="主檔案 ID"
            :rules="[rules.required, rules.numeric]"
            maxlength="22"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_DT -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_DT"
            label="評估日期"
            :rules="[rules.required, rules.dateFormat]"
            maxlength="8"
            placeholder="yyyyMMdd"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_USER -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_USER"
            readonly
            label="評估人員"
            :rules="[rules.required]"
            maxlength="200"
            required
          ></v-text-field>
        </v-col>
        <v-col class="pa-0" cols="12"> <v-divider /></v-col>

        <!-- REMARK -->
        <v-col cols="12">
          <v-textarea
            outlined
            v-model="form.REMARK"
            label="備註"
            :rules="[rules.optional]"
            maxlength="500"
          ></v-textarea>
        </v-col>

        <!-- IS_WEIGHT -->
        <v-col cols="12" md="4">
          <v-radio-group v-model="form.IS_WEIGHT" :rules="[rules.required]" label="體重減輕">
            <v-radio label="是" :value="true"></v-radio>
            <v-radio label="否" :value="false"></v-radio>
          </v-radio-group>
        </v-col>

        <!-- IS_LIMBS -->
        <v-col cols="12" md="4">
          <v-radio-group v-model="form.IS_LIMBS" :rules="[rules.required]" label="下肢功能">
            <v-radio label="是" :value="true"></v-radio>
            <v-radio label="否" :value="false"></v-radio>
          </v-radio-group>
        </v-col>

        <!-- IS_ACT -->
        <v-col cols="12" md="4">
          <v-radio-group v-model="form.IS_ACT" :rules="[rules.required]" label="活力降低">
            <v-radio label="是" :value="true"></v-radio>
            <v-radio label="否" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row class="pa-2" justify="center"
        ><v-btn color="primary" @click="submitForm"> 送出表單 </v-btn></v-row
      >
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      isFormValid: false,
      form: {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        REMARK: '',
        IS_WEIGHT: null,
        IS_LIMBS: null,
        IS_ACT: null,
      },
      rules: {
        required: (value) => value !== null || '此欄位為必填項目',
        numeric: (value) => !isNaN(value) || '必須是數字',
        dateFormat: (value) => /\d{8}/.test(value) || '請輸入正確的日期格式 yyyyMMdd',
        optional: () => true,
      },
    }
  },
  methods: {
    clearForm() {
      this.form = {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        REMARK: '',
        IS_WEIGHT: null,
        IS_LIMBS: null,
        IS_ACT: null,
      }
      this.$refs.form.resetValidation()
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        console.log(this.form)
        try {
          const response = await axios.post('/api/mohw_assessment/qc113', this.form)

          if (response.status === 200) {
            alert('資料成功上傳！')
            this.clearForm()
          } else {
            throw new Error('資料上傳失敗')
          }
        } catch (error) {
          console.log(`上傳失敗：${error.response?.data?.message || error.message}`)
        }
      }
    },
  },
}
</script>
