<template>
  <v-container>
    <v-form ref="form" v-model="isFormValid">
      <v-row>
        <!-- MAIN_ID -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.MAIN_ID"
            label="主檔案 ID"
            :rules="[rules.required, rules.numeric]"
            maxlength="22"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_DT -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_DT"
            label="評估日期"
            :rules="[rules.required, rules.dateFormat]"
            maxlength="8"
            placeholder="yyyyMMdd"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_USER -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_USER"
            readonly
            label="評估人員"
            :rules="[rules.required]"
            maxlength="100"
            required
          ></v-text-field>
        </v-col>
        <v-col class="pa-0" cols="12"> <v-divider /></v-col>

        <!-- IS_OLD -->
        <v-col cols="12" md="4">
          <v-checkbox
            v-model="form.IS_OLD"
            label="年長 65 歲以上"
            :rules="[rules.required]"
          ></v-checkbox>
        </v-col>

        <!-- IS_FALL -->
        <v-col cols="12" md="4">
          <v-checkbox
            v-model="form.IS_FALL"
            label="過去一年內曾跌倒"
            :rules="[rules.required]"
          ></v-checkbox>
        </v-col>

        <!-- IS_COGNITION -->
        <v-col cols="12" md="4">
          <v-checkbox
            v-model="form.IS_COGNITION"
            label="最近意識/認知出現異常"
            :rules="[rules.required]"
          ></v-checkbox>
          <v-select
            outlined
            v-if="form.IS_COGNITION"
            v-model="form.COGNITION_ITEM"
            :items="cognitionOptions"
            label="異常項目 (多選)"
            multiple
          ></v-select>
        </v-col>

        <!-- IS_ACTLESS -->
        <v-col cols="12" md="4">
          <v-checkbox
            v-model="form.IS_ACTLESS"
            label="活動功能異常"
            :rules="[rules.required]"
          ></v-checkbox>
          <v-select
            outlined
            v-if="form.IS_ACTLESS"
            v-model="form.ACTLESS_ITEM"
            :items="actlessOptions"
            label="異常項目 (多選)"
            multiple
          ></v-select>
        </v-col>

        <!-- IS_WEAK -->
        <v-col cols="12" md="4">
          <v-checkbox
            v-model="form.IS_WEAK"
            label="體能虛弱"
            :rules="[rules.required]"
          ></v-checkbox>
          <v-select
            outlined
            v-if="form.IS_WEAK"
            v-model="form.WEAK_ITEM"
            :items="weakOptions"
            label="異常項目 (多選)"
            multiple
          ></v-select>
        </v-col>

        <!-- IS_FEEL -->
        <v-col cols="12" md="4">
          <v-checkbox
            v-model="form.IS_FEEL"
            label="知覺障礙"
            :rules="[rules.required]"
          ></v-checkbox>
          <v-select
            outlined
            v-if="form.IS_FEEL"
            v-model="form.FEEL_ITEM"
            :items="feelOptions"
            label="異常項目 (多選)"
            multiple
          ></v-select>
        </v-col>

        <!-- IS_DIZZINESS -->
        <v-col cols="12" md="4">
          <v-checkbox
            v-model="form.IS_DIZZINESS"
            label="暈眩/低血壓"
            :rules="[rules.required]"
          ></v-checkbox>
          <v-select
            outlined
            v-if="form.IS_DIZZINESS"
            v-model="form.DIZZINESS_ITEM"
            :items="dizzinessOptions"
            label="異常項目 (多選)"
            multiple
          ></v-select>
        </v-col>

        <!-- IS_MED -->
        <v-col cols="12" md="4">
          <v-checkbox v-model="form.IS_MED" label="使用藥物" :rules="[rules.required]"></v-checkbox>
          <v-select
            outlined
            v-if="form.IS_MED"
            v-model="form.MED_ITEM"
            :items="medOptions"
            label="使用藥物類型 (多選)"
            multiple
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="pa-2" justify="center"
        ><v-btn color="primary" @click="submitForm"> 送出表單 </v-btn></v-row
      >
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      isFormValid: false,
      form: {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        IS_OLD: false,
        IS_FALL: false,
        IS_COGNITION: false,
        COGNITION_ITEM: [],
        IS_ACTLESS: false,
        ACTLESS_ITEM: [],
        IS_WEAK: false,
        WEAK_ITEM: [],
        IS_FEEL: false,
        FEEL_ITEM: [],
        IS_DIZZINESS: false,
        DIZZINESS_ITEM: [],
        IS_MED: false,
        MED_ITEM: [],
      },
      rules: {
        required: (value) => (value !== null && value !== undefined) || '此欄位為必填項目',
        numeric: (value) => !isNaN(value) || '必須是數字',
        dateFormat: (value) => /\d{8}/.test(value) || '請輸入正確的日期格式 yyyyMMdd',
      },
      cognitionOptions: [
        { text: '曾突然意識改變', value: '5' },
        { text: '曾突然全身性抽搐', value: '10' },
        { text: '目前意識狀態變差', value: '15' },
        { text: '目前心智狀態變差', value: '20' },
        { text: '合作度差', value: '30' },
      ],
      actlessOptions: [
        { text: '活動障礙', value: '5' },
        { text: '一側肢體肌力≤4分', value: '10' },
        { text: '平衡感障礙', value: '15' },
        { text: '行走協調功能差', value: '20' },
      ],
      weakOptions: [
        { text: '無力', value: '5' },
        { text: '活動費力', value: '10' },
        { text: '身體耐受力不足', value: '15' },
        { text: '行動遲緩', value: '20' },
      ],
      feelOptions: [
        { text: '雙眼視力障礙', value: '5' },
        { text: '位置感缺失', value: '10' },
      ],
      dizzinessOptions: [
        { text: '頭暈', value: '5' },
        { text: '暈眩', value: '10' },
        { text: '姿位性低血壓', value: '15' },
        { text: '低血壓', value: '20' },
      ],
      medOptions: [
        { text: '麻醉止痛劑', value: '5' },
        { text: '鎮靜安眠劑', value: '10' },
        { text: '擴管劑', value: '15' },
        { text: '鎮靜劑', value: '20' },
        { text: '肌肉鬆弛劑', value: '25' },
        { text: '降壓利尿劑', value: '30' },
        { text: '藥劑', value: '35' },
        { text: '抗癲劑', value: '40' },
        { text: '其他', value: '99' },
      ],
    }
  },
  methods: {
    clearForm() {
      this.form = {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        IS_OLD: false,
        IS_FALL: false,
        IS_COGNITION: false,
        COGNITION_ITEM: [],
        IS_ACTLESS: false,
        ACTLESS_ITEM: [],
        IS_WEAK: false,
        WEAK_ITEM: [],
        IS_FEEL: false,
        FEEL_ITEM: [],
        IS_DIZZINESS: false,
        DIZZINESS_ITEM: [],
        IS_MED: false,
        MED_ITEM: [],
      }
      this.$refs.form.resetValidation()
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        console.log(this.form)
        try {
          const response = await axios.post('/api/mohw_assessment/qc106', this.form)

          if (response.status === 200) {
            alert('資料成功上傳！')
            this.clearForm()
          } else {
            throw new Error('資料上傳失敗')
          }
        } catch (error) {
          console.log(`上傳失敗：${error.response?.data?.message || error.message}`)
        }
      }
    },
  },
}
</script>
