<template>
  <v-container>
    <v-form ref="form" v-model="isFormValid">
      <v-row>
        <!-- MAIN_ID -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.MAIN_ID"
            label="主檔案 ID"
            :rules="[rules.required, rules.numeric]"
            maxlength="22"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_DT -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_DT"
            label="評估日期"
            :rules="[rules.required, rules.dateFormat]"
            maxlength="8"
            placeholder="yyyyMMdd"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_USER -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_USER"
            readonly
            label="評估人員"
            :rules="[rules.required]"
            maxlength="200"
            required
          ></v-text-field>
        </v-col>
        <v-col class="pa-0" cols="12"> <v-divider /></v-col>

        <!-- REMARK -->
        <v-col cols="12">
          <v-textarea
            outlined
            v-model="form.REMARK"
            label="備註"
            :rules="[rules.optional]"
            maxlength="500"
          ></v-textarea>
        </v-col>

        <!-- QC112_A_LIST -->
        <v-col cols="12">
          <v-row v-for="(item, index) in form.QC112_A_LIST" :key="index" class="mt-2">
            <v-col cols="12" md="3">
              <v-text-field
                outlined
                v-model="item.PARTS"
                label="部位"
                :rules="[rules.required]"
                maxlength="10"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field
                outlined
                v-model="item.SCORE"
                label="分數(1-10的整數)"
                :rules="[rules.required, rules.scoreRange]"
                type="number"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                outlined
                v-model="item.TIME"
                label="時間"
                :rules="[rules.required, rules.dateTimeFormat]"
                maxlength="12"
                placeholder="yyyyMMddHHmm"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field
                outlined
                v-model="item.NATURE"
                label="性質"
                :rules="[rules.required]"
                maxlength="10"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field
                outlined
                v-model="item.REMARK"
                label="備註"
                :rules="[rules.optional]"
                maxlength="100"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-btn color="error" @click="removePainRecord(index)"> 刪除紀錄 </v-btn>
            </v-col>
          </v-row>

          <v-btn class="mt-4" color="primary" @click="addPainRecord"> 新增疼痛紀錄 </v-btn>
        </v-col>
      </v-row>

      <v-row class="pa-2" justify="center"
        ><v-btn color="primary" @click="submitForm"> 送出表單 </v-btn></v-row
      >
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      isFormValid: false,
      form: {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        REMARK: '',
        QC112_A_LIST: [
          {
            PARTS: '',
            SCORE: '',
            TIME: '',
            NATURE: '',
            REMARK: '',
          },
        ],
      },
      rules: {
        required: (value) => !!value || '此欄位為必填項目',
        numeric: (value) => !isNaN(value) || '必須是數字',
        dateFormat: (value) => /\d{8}/.test(value) || '請輸入正確的日期格式 yyyyMMdd',
        dateTimeFormat: (value) => /\d{12}/.test(value) || '請輸入正確的日期時間格式 yyyyMMddHHmm',
        scoreRange: (value) => (value >= 1 && value <= 10) || '分數必須介於 1 到 10',
        optional: () => true,
      },
    }
  },
  methods: {
    addPainRecord() {
      this.form.QC112_A_LIST.push({
        PARTS: '',
        SCORE: '',
        TIME: '',
        NATURE: '',
        REMARK: '',
      })
    },
    removePainRecord(index) {
      this.form.QC112_A_LIST.splice(index, 1)
    },
    clearForm() {
      this.form = {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        REMARK: '',
        QC112_A_LIST: [
          {
            PARTS: '',
            SCORE: '',
            TIME: '',
            NATURE: '',
            REMARK: '',
          },
        ],
      }
      this.$refs.form.resetValidation()
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        console.log(this.form)
        try {
          const response = await axios.post('/api/mohw_assessment/qc112', this.form)

          if (response.status === 200) {
            alert('資料成功上傳！')
            this.clearForm()
          } else {
            throw new Error('資料上傳失敗')
          }
        } catch (error) {
          console.log(`上傳失敗：${error.response?.data?.message || error.message}`)
        }
      }
    },
  },
}
</script>
