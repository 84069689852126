<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <!-- 主檔案欄位 -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            label="主檔案ID"
            v-model="form.MAIN_ID"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            label="評估日期"
            v-model="form.ASSESSMENT_DT"
            :rules="[rules.required, rules.dateFormat]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            readonly
            label="評估人員"
            v-model="form.ASSESSMENT_USER"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col class="pa-0" cols="12"> <v-divider /></v-col>

        <v-col cols="12" sm="6" md="4">
          <v-select
            outlined
            label="是否有長期使用藥物"
            v-model="form.IS_LONG_MED"
            :items="medicationOptions.IS_LONG_MED"
            :rules="[rules.required]"
            item-text="name"
            item-value="code"
            @change="validateMedicationUsage"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            label="目前使用中的藥物種類共幾種"
            v-model="form.Q1_TOTAL"
            :rules="[rules.requiredIf(form.IS_LONG_MED === '1'), rules.number]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            label="目前使用中的藥物種類狀況"
            v-model="form.Q1_UNKNOWN"
            :rules="[rules.requiredIf(form.IS_LONG_MED === '1')]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-select
            outlined
            label="使用精神用藥"
            v-model="form.Q1_1"
            :items="medicationOptions.Q1_1"
            :rules="[rules.required]"
            item-text="name"
            item-value="code"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-select
            outlined
            label="使用止痛用藥"
            v-model="form.Q1_2"
            :items="medicationOptions.Q1_2"
            :rules="[rules.required]"
            item-text="name"
            item-value="code"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-select
            outlined
            label="是否存在多重用藥問題"
            v-model="form.Q2"
            :items="medicationOptions.Q2"
            :rules="[rules.required]"
            item-text="name"
            item-value="code"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-select
            outlined
            label="目前是否使用有自行購藥或保健食品"
            v-model="form.Q3"
            :items="medicationOptions.Q3"
            :rules="[rules.required]"
            item-text="name"
            item-value="code"
            @change="validateSelfMedication"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            label="目前是否使用自備藥或保健食品共幾種"
            v-model="form.Q3_TOTAL"
            :rules="[rules.requiredIf(form.IS_LONG_MED === '1'), rules.number]"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- 動態處理 QC103_A_LIST -->
      <v-row>
        <v-col cols="12">
          <v-btn color="success" @click="openMedicationDialog">新增藥品項目</v-btn>
        </v-col>

        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="form.QC103_A_LIST"
            item-value="ITEM"
            dense
            disable-sort
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>藥物項目</v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon @click="editMedication(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="removeMedication(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="pa-2" justify="center"
        ><v-btn color="primary" @click="submitForm"> 送出表單 </v-btn></v-row
      >

      <!-- 新增或編輯藥物對話框 -->
      <v-dialog v-model="medicationDialog" max-width="600px">
        <v-card>
          <v-card-title>藥物項目</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field label="品項" v-model="medicationForm.ITEM"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="劑量" v-model="medicationForm.DOSAGE"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="劑量單位"
                    v-model="medicationForm.DOSAGE_MEASUREMENT"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="頻率(天)" v-model="medicationForm.FREQUENCY"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="目的" v-model="medicationForm.PURPOSE"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="備註" v-model="medicationForm.REMARK"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeMedicationDialog">取消</v-btn>
            <v-btn color="blue darken-1" text @click="saveMedication">確定</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      valid: false,
      form: {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        IS_LONG_MED: '',
        Q1_TOTAL: '',
        Q1_UNKNOWN: '',
        Q1_1: '',
        Q1_2: '',
        Q2: '',
        Q3: '',
        Q3_TOTAL: '',
        QC103_A_LIST: [],
      },
      headers: [
        { text: '品項', value: 'ITEM', align: 'center' },
        { text: '劑量', value: 'DOSAGE', align: 'center' },
        { text: '劑量單位', value: 'DOSAGE_MEASUREMENT', align: 'center' },
        { text: '頻率(天)', value: 'FREQUENCY', align: 'center' },
        { text: '目的', value: 'PURPOSE', align: 'center' },
        { text: '備註', value: 'REMARK', align: 'center' },
        { text: '編輯/刪除', value: 'actions', align: 'center' },
      ],
      rules: {
        required: (value) => !!value || '此欄位為必填項目',
        requiredIf: (condition) => (value) => !condition || !!value || '此欄位為必填項目',
        dateFormat: (value) => /\d{4}[01]\d[0-3]\d/.test(value) || '日期格式錯誤，請使用 yyyyMMdd',
        number: (value) => !isNaN(Number(value)) || '此欄位必須是數字',
      },
      medicationOptions: {
        IS_LONG_MED: [
          { code: '1', name: '是' },
          { code: '0', name: '否' },
        ],
        Q1_1: [
          { code: '1', name: '是' },
          { code: '0', name: '否' },
        ],
        Q1_2: [
          { code: '1', name: '是' },
          { code: '0', name: '否' },
        ],
        Q2: [
          { code: '5', name: '無' },
          { code: '10', name: '有' },
          { code: '15', name: '不知道' },
        ],
        Q3: [
          { code: '1', name: '是' },
          { code: '0', name: '否' },
        ],
      },
      medicationDialog: false,
      medicationForm: {
        ITEM: '',
        DOSAGE: '',
        DOSAGE_MEASUREMENT: '',
        FREQUENCY: '',
        PURPOSE: '',
        REMARK: '',
      },
      editingIndex: null,
    }
  },
  methods: {
    openMedicationDialog() {
      this.medicationForm = {
        ITEM: '',
        DOSAGE: '',
        DOSAGE_MEASUREMENT: '',
        FREQUENCY: '',
        PURPOSE: '',
        REMARK: '',
      }
      this.editingIndex = null
      this.medicationDialog = true
    },
    closeMedicationDialog() {
      this.medicationDialog = false
    },
    saveMedication() {
      if (this.editingIndex === null) {
        this.form.QC103_A_LIST.push({ ...this.medicationForm })
      } else {
        this.form.QC103_A_LIST.splice(this.editingIndex, 1, { ...this.medicationForm })
      }
      this.closeMedicationDialog()
    },
    editMedication(item) {
      this.editingIndex = this.form.QC103_A_LIST.indexOf(item)
      this.medicationForm = { ...item }
      this.medicationDialog = true
    },
    removeMedication(item) {
      const index = this.form.QC103_A_LIST.indexOf(item)
      if (index > -1) this.form.QC103_A_LIST.splice(index, 1)
    },
    validateMedicationUsage() {
      if (this.form.IS_LONG_MED === '1') {
        this.rules.requiredIf(true)(this.form.Q1_TOTAL)
        this.rules.requiredIf(true)(this.form.Q1_UNKNOWN)
      }
    },
    validateSelfMedication() {
      if (this.form.IS_LONG_MED === '1') {
        this.rules.requiredIf(true)(this.form.Q3_TOTAL)
      }
    },
    clearForm() {
      this.form = {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        IS_LONG_MED: '',
        Q1_TOTAL: '',
        Q1_UNKNOWN: '',
        Q1_1: '',
        Q1_2: '',
        Q2: '',
        Q3: '',
        Q3_TOTAL: '',
        QC103_A_LIST: [],
      }
      this.$refs.form.resetValidation()
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        console.log(this.form)
        try {
          const response = await axios.post('/api/mohw_assessment/qc103', this.form)

          if (response.status === 200) {
            alert('資料成功上傳！')
            this.clearForm()
          } else {
            throw new Error('資料上傳失敗')
          }
        } catch (error) {
          console.log(`上傳失敗：${error.response?.data?.message || error.message}`)
        }
      }
    },
  },
}
</script>
<style scoped></style>
