<template>
  <div class="custom-margin">
    <v-row dense class="ma-2">
      <div>
        <v-btn color="#141E46" class="white--text" @click="addCases">
          建立新個案
        </v-btn>
      </div>
      <v-col sm="auto">
        <v-select
          dense
          outlined
          placeholder="選擇編組"
          :items="groupList"
          v-model="selectedGroup"
          class="pa-2"
          hide-details=""
          @change="onGroupChange"
        ></v-select>
      </v-col>
    </v-row>
    <div class="ma-2 table-row">
      <v-data-table
        :headers="headers"
        :items="filteredRecords"
        :items-per-page="-1"
        class="pa-2 table"
        disable-sort
        hide-default-footer
        dense
      >
        <template v-slot:[`item.name`]="{ item }">
          <span class="hover-effect" @click="editCases(item)">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:[`item.id_number`]="{ item, index }">
          <span
            v-if="item.id_number == null"
            class="text-add hover-add"
            @mouseover="colorItems.id_number = '#258525'"
            @mouseout="colorItems.id_number = '#8d8d8d'"
            @click="editCases(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editCases(item)">
            {{ item.id_number }}
          </span>
        </template>
        <template v-slot:[`item.birth`]="{ item, index }">
          <span
            v-if="item.birth == null"
            class="text-add hover-add"
            @mouseover="colorItems.birth = '#258525'"
            @mouseout="colorItems.birth = '#8d8d8d'"
            @click="editCases(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editCases(item)">
            {{ item.birth }}
          </span>
        </template>
        <template v-slot:[`item.gender`]="{ item, index }">
          <span
            v-if="item.gender == null"
            class="text-add hover-add"
            @mouseover="colorItems.gender = '#258525'"
            @mouseout="colorItems.gender = '#8d8d8d'"
            @click="editCases(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editCases(item)">
            {{ item.gender == "1" ? "男" : "女" }}
          </span>
        </template>
        <template v-slot:[`item.phone`]="{ item, index }">
          <span
            v-if="item.phone == null"
            class="text-add hover-add"
            @mouseover="colorItems.phone = '#258525'"
            @mouseout="colorItems.phone = '#8d8d8d'"
            @click="editCases(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editCases(item)">
            {{ item.phone }}
          </span>
        </template>
        <template v-slot:[`item.contact_name`]="{ item, index }">
          <span
            v-if="item.contact_name == null"
            class="text-add hover-add"
            @mouseover="colorItems.contact_name = '#258525'"
            @mouseout="colorItems.contact_name = '#8d8d8d'"
            @click="editCases(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editCases(item)">
            {{ item.contact_name }}
          </span>
        </template>
        <template v-slot:[`item.contact_phone`]="{ item, index }">
          <span
            v-if="item.contact_phone == null"
            class="text-add hover-add"
            @mouseover="colorItems.contact_phone = '#258525'"
            @mouseout="colorItems.contact_phone = '#8d8d8d'"
            @click="editCases(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editCases(item)">
            {{ item.contact_phone }}
          </span>
        </template>
        <template v-slot:[`item.status`]="{ item, index }">
          <span
            v-if="item.status == null"
            class="text-add hover-add"
            @mouseover="colorItems.status = '#258525'"
            @mouseout="colorItems.status = '#8d8d8d'"
            @click="editCases(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editCases(item)">
            {{ item.status }}
          </span>
        </template>
        <template v-slot:[`item.group_1`]="{ item, index }">
          <span
            v-if="item.group_1 == null"
            class="text-add hover-add"
            @mouseover="colorItems.group_1 = '#258525'"
            @mouseout="colorItems.group_1 = '#8d8d8d'"
            @click="editCases(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editCases(item)">
            {{ item.group_1 }}
          </span>
        </template>
        <template v-slot:[`item.group_2`]="{ item, index }">
          <span
            v-if="item.group_2 == null"
            class="text-add hover-add"
            @mouseover="colorItems.group_2 = '#258525'"
            @mouseout="colorItems.group_2 = '#8d8d8d'"
            @click="editCases(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editCases(item)">
            {{ item.group_2 }}
          </span>
        </template>
      </v-data-table>
    </div>

    <v-dialog persistent v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <div v-if="isEdit">個案資料編輯 - {{ editedData.editedName }}</div>
          <div v-else>建立新個案</div>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  label="姓名"
                  v-model="editedData.editedName"
                  :rules="[v => !!v || '*必填']"
                  hide-details=""
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="身份證字號"
                  v-model="editedData.editedIdNumber"
                  :rules="[v => !!v || '*必填']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="生日(yyyy-mm-dd)"
                  v-model="editedData.editedBirth"
                  maxlength="10"
                  :rules="[v => !!v || '*必填']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  dense
                  outlined
                  hide-details=""
                  label="性別"
                  v-model="editedData.editedGender"
                  :items="genderOptions"
                  :rules="[v => !!v || '*必填']"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="電話"
                  :value="editedData.editedPhone"
                  maxlength="20"
                  @input="updateOptionField('editedPhone', $event)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-textarea
                  dense
                  outlined
                  hide-details=""
                  label="地址"
                  :value="editedData.editedAddress"
                  @input="updateOptionField('editedAddress', $event)"
                  rows="1"
                  auto-grow
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="4">
                <v-textarea
                  dense
                  outlined
                  hide-details=""
                  label="經度"
                  :value="editedData.editedLongitude"
                  @input="updateOptionField('editedLongitude', $event)"
                  rows="1"
                  auto-grow
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="4">
                <v-textarea
                  dense
                  outlined
                  hide-details=""
                  label="緯度"
                  :value="editedData.editedLatitude"
                  @input="updateOptionField('editedLatitude', $event)"
                  rows="1"
                  auto-grow
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="4">
                <v-textarea
                  dense
                  outlined
                  hide-details=""
                  label="CMS 等級"
                  :value="editedData.editedCmsLevel"
                  @input="updateOptionField('editedCmsLevel', $event)"
                  rows="1"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider />
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="聯絡人姓名"
                  :value="editedData.editedContactName"
                  @input="updateOptionField('editedContactName', $event)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="聯絡人電話"
                  :value="editedData.editedContactPhone"
                  maxlength="20"
                  @input="updateOptionField('editedContactPhone', $event)"
                ></v-text-field> </v-col
            ></v-row>
            <v-divider />
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-select
                  dense
                  outlined
                  hide-details=""
                  label="服務單位"
                  v-model="editedData.editedGroup1"
                  :items="institutionOptions"
                  :rules="[v => !!v || '*必填']"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-combobox
                  ref="group2Combobox"
                  dense
                  outlined
                  hide-details=""
                  label="編組"
                  v-model="editedData.editedGroup2"
                  :items="groupOptions"
                  :clearable="true"
                  :rules="[v => !!v || '*必填']"
                ></v-combobox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  dense
                  outlined
                  hide-details=""
                  label="個案狀態"
                  v-model="editedData.editedStatus"
                  :items="statusOptions"
                  :rules="[v => !!v || '*必填']"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row dense class="justify-space-around">
            <v-btn text color="red" @click="dialog = false">取消</v-btn>
            <v-btn text color="green" @click="submit">送出</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      headers: [
        {
          text: "姓名",
          value: "name",
          align: "center",
          width: "100px",
        },
        {
          text: "身份證字號",
          value: "id_number",
          align: "center",
          width: "75px",
        },
        { text: "生日", value: "birth", align: "center", width: "75px" },
        { text: "性別", value: "gender", align: "center", width: "75px" },
        { text: "電話", value: "phone", align: "center", width: "75px" },
        {
          text: "聯絡人姓名",
          value: "contact_name",
          align: "center",
          width: "75px",
        },
        {
          text: "聯絡人電話",
          value: "contact_phone",
          align: "center",
          width: "75px",
        },
        { text: "個案狀態", value: "status", align: "center", width: "75px" },
        { text: "服務單位", value: "group_1", align: "center", width: "75px" },
        { text: "編組", value: "group_2", align: "center", width: "75px" },
      ],
      records: [
        // {
        //   name: "李嘉豪",
        //   id_number: "A123456789",
        //   birth: "1957-11-01",
        //   gender: "1",
        //   phone: "02-234567",
        //   contact_name: "李嘉誠",
        //   contact_phone: "0912555666",
        //   status: "開案服務中",
        //   group_1: null,
        //   group_2: null,
        // },
        // {
        //   name: "張曉雯",
        //   id_number: "Q233456788",
        //   birth: "1942-06-04",
        //   gender: "2",
        //   phone: "02-9876543",
        //   contact_name: "陳大有",
        //   contact_phone: "0932552784",
        //   status: "暫存",
        //   group_1: null,
        //   group_2: null,
        // },
        // {
        //   name: "陳建宇",
        //   id_number: "S121221333",
        //   birth: "1957-11-01",
        //   gender: "1",
        //   phone: "02-2214785",
        //   contact_name: "陳默",
        //   contact_phone: "0955184988",
        //   status: "開案服務中",
        //   group_1: null,
        //   group_2: null,
        // },
      ],
      dialog: false,
      isEdit: false,
      staff_id: "",
      case_id: "",
      index: "",
      caseName: "",
      time: "",
      phone: "",
      contact_name: "",
      contact_phone: "",
      group_2: "",
      colorItems: {
        id_number: "#8d8d8d",
        birth: "#8d8d8d",
        gender: "#8d8d8d",
        phone: "#8d8d8d",
        contact_name: "#8d8d8d",
        contact_phone: "#8d8d8d",
        status: "#8d8d8d",
        group_1: "#8d8d8d",
        group_2: "#8d8d8d",
      },
      menu: false,
      selectedDate: new Date(),
      statusOptions: [],
      institutionOptions: [],
      groupOptions: [],
      genderOptions: [
        { text: "男", value: 1 },
        { text: "女", value: 2 },
      ],
      editedData: {
        editedCaseNum: null,
        editedName: null,
        editedIdNumber: null,
        editedBirth: null,
        editedGender: null,
        editedPhone: null,
        editedContactName: null,
        editedContactPhone: null,
        editedStatus: null,
        editedGroup1: null,
        editedGroup2: null,
        editedAddress: null,
        editedLongitude: null,
        editedLatitude: null,
        editedCmsLevel: null,
      },
      filteredRecords: [],
      selectedGroup: null,
      groupList: [],
    };
  },
  methods: {
    // formatDate(value) {
    //   console.log(value)
    //   return moment(value).format('YYYY-MM-DD')
    // },
    async getList() {
      // console.log(this.formattedDate);
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/attendance/list_case_info`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        // console.log(res.data.data);
        this.records = res.data.data;
        this.filteredRecords = res.data.data;
        this.groupList = [
          ...new Set(res.data.data.map((item) => item.group_2)),
        ];
      } catch (error) {
        console.log(error);
      }
    },
    initialInputData() {
      for (let key in this.editedData) {
        if (this.editedData[key]) {
          this.editedData[key] = null;
        }
      }
    },
    async getInstitutionOptions() {
      // console.log(this.formattedDate);
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/attendance/list_institution`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        // console.log(res.data.data);
        this.institutionOptions = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getGroupOptions() {
      // console.log(this.formattedDate);
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/attendance/list_group`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        // console.log(res.data.data);
        this.groupOptions = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getCaseStatus() {
      // console.log(this.formattedDate);
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/attendance/list_case_status`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        // console.log(res.data.data);
        this.statusOptions = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        alert("請確認必填選項");
      }
      const axios = require("axios");
      const qs = require("qs");
      let data = qs.stringify({
        name: this.editedData.editedName,
        phone: this.editedData.editedPhone,
        birth: this.editedData.editedBirth,
        gender: this.editedData.editedGender,
        id_num: this.editedData.editedIdNumber,
        status: this.editedData.editedStatus,
        contact_name: this.editedData.editedContactName,
        contact_phone: this.editedData.editedContactPhone,
        group1: this.editedData.editedGroup1,
        group2: this.editedData.editedGroup2,
        address: this.editedData.editedAddress,
        longitude: this.editedData.editedLongitude,
        latitude: this.editedData.editedLatitude,
        cms_level: this.editedData.editedCmsLevel,
      });
      let config = {};
      if (this.isEdit === true) {
        console.log(this.isEdit);
        console.log(this.editedData.editedCaseNum);
        config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `/api/attendance/edit_case/${this.editedData.editedCaseNum}/`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: data,
        };
      }
      else {
        config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `/api/attendance/create_case`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: data,
        };
      }
      try {
        let res = await axios.request(config);
        console.log(res.data);
        this.dialog = false;
        this.$router.go(this.$router.currentRoute);
      } catch (error) {
        alert(error);
      }
    },
    addCases() {
      this.isEdit = false;
      this.initialInputData();
      this.dialog = true;
    },
    editCases(item) {
      this.isEdit = true;
      this.editedData.editedCaseNum = item.case_num;
      this.editedData.editedName = item.name;
      this.editedData.editedPhone = item.phone;
      this.editedData.editedBirth = item.birth;
      this.editedData.editedGender = item.gender;
      this.editedData.editedIdNumber = item.id_number;
      this.editedData.editedStatus = item.status;
      this.editedData.editedContactName = item.contact_name;
      this.editedData.editedContactPhone = item.contact_phone;
      this.editedData.editedGroup1 = item.group_1;
      this.editedData.editedGroup2 = item.group_2;
      this.editedData.editedAddress = item.address;
      this.editedData.editedLongitude = item.longitude;
      this.editedData.editedLatitude = item.latitude;
      this.editedData.editedCmsLevel = item.cms_level;
      this.dialog = true;
    },
    onGroupChange() {
      console.log("changeGroup");
      console.log(this.selectedGroup);
      if (this.selectedGroup) {
        this.filteredRecords = this.records.filter(
          (item) => item.group_2 === this.selectedGroup
        );
      } else {
        this.filteredRecords = this.records;
      }
    },
    updateOptionField(fieldName, value) {
      this.editedData[fieldName] = value || null;
      console.log(this.editedData[fieldName]);
    },
  },
  computed: {},
  mounted() {
    this.getList();
    this.getInstitutionOptions();
    this.getGroupOptions();
    this.getCaseStatus();
  },
  watch: {
    "editedData.editedBirth": function (newValue, oldValue) {
      // 檢查newValue是否符合日期格式
      if (oldValue.length <= newValue.length && newValue.length === 5) {
        this.editedData.editedBirth = newValue.slice(0, 4) + "-" + newValue.slice(4);
      } else if (oldValue.length <= newValue.length && newValue.length === 8) {
        this.editedData.editedBirth = newValue.slice(0, 7) + "-" + newValue.slice(7);
      }
      
      
    },
  },
  created() {
    let staff = sessionStorage.getItem("staff");
    this.staff_id = JSON.parse(staff).id;
  },
};
</script>

<style scoped>
.hover-effect:hover {
  cursor: pointer;
  color: #4477ce;
}
.hover-add:hover {
  cursor: pointer;
  color: #258525;
}
.custom-margin {
  overflow-x: auto !important;
  /* max-width: fit-content;。 */
}

.table-row {
  width: 90vw;
}

.table {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
}

.text-add {
  font-size: 12px;
  color: #8d8d8d;
}
</style>
