<template>
  <v-container>
    <v-form ref="form" v-model="isFormValid">
      <v-row>
        <!-- MAIN_ID -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.MAIN_ID"
            label="主檔案 ID"
            :rules="[rules.required, rules.numeric]"
            maxlength="22"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_DT -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_DT"
            label="評估日期"
            :rules="[rules.required, rules.dateFormat]"
            maxlength="8"
            placeholder="yyyyMMdd"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_USER -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_USER"
            readonly
            label="評估人員"
            :rules="[rules.required]"
            maxlength="200"
            required
          ></v-text-field>
        </v-col>
        <v-col class="pa-0" cols="12"> <v-divider /></v-col>

        <!-- FEEL -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.FEEL"
            :items="feelOptions"
            label="知覺感受"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- WET -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.WET"
            :items="wetOptions"
            label="潮濕程度"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- ACT -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.ACT"
            :items="actOptions"
            label="活動能力"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- MOVE -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.MOVE"
            :items="moveOptions"
            label="移動能力"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- NUTRITION -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.NUTRITION"
            :items="nutritionOptions"
            label="營養攝取"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- FRICTION -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.FRICTION"
            :items="frictionOptions"
            label="摩擦/剪力"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="pa-2" justify="center"
        ><v-btn color="primary" @click="submitForm"> 送出表單 </v-btn></v-row
      >
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      isFormValid: false,
      form: {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        FEEL: '',
        WET: '',
        ACT: '',
        MOVE: '',
        NUTRITION: '',
        FRICTION: '',
      },
      rules: {
        required: (value) => !!value || '此欄位為必填項目',
        numeric: (value) => !isNaN(value) || '必須是數字',
        dateFormat: (value) => /\d{8}/.test(value) || '請輸入正確的日期格式 yyyyMMdd',
      },
      feelOptions: [
        { text: '４分 - 沒有受損：對指令有反應；能表達不適', value: '20' },
        {
          text: '３分 - 稍微受限：對指令有反應，但有時無法表達不適(一或兩個肢體對疼痛或不適的感覺能力受限)',
          value: '15',
        },
        {
          text: '２分 - 大部分受限：只對疼痛刺激有反應，除了呻吟或焦躁不安，無法表達不適',
          value: '10',
        },
        { text: '１分 - 完全受限：因為意識減弱或使用鎮定劑，對疼痛刺激沒有反應', value: '5' },
      ],
      wetOptions: [
        {
          text: '４分 - 很少潮溼：皮膚通常保持乾燥，衣褲/尿布/看護墊等只需要依常規的時間，每天更換一次',
          value: '20',
        },
        {
          text: '３分 - 偶爾潮溼：皮膚偶爾是浸潤的，衣褲/尿布/看護墊等至少每8小時需更換一次',
          value: '15',
        },
        {
          text: '２分 - 經常潮溼：皮膚經常是浸潤的，衣褲/尿布/看護墊等至少每8小時需更換兩次以上',
          value: '10',
        },
        {
          text: '１分 - 總是潮溼：皮膚總是被汗水、大小便等浸潤，每兩小時予病人翻身時，都會發現皮膚是潮溼的',
          value: '5',
        },
      ],
      actOptions: [
        {
          text: '４分 - 經常走動',
          value: '20',
        },
        {
          text: '３分 - 偶爾走動：但是步行短距離，有時需要協助，大部份時間在床上或椅子上',
          value: '15',
        },
        {
          text: '２分 - 受限於椅子：無法行走或走路極為困難，無法支撐自己的體重',
          value: '10',
        },
        {
          text: '１分 - 受限於床上',
          value: '5',
        },
      ],
      moveOptions: [
        {
          text: '４分 - 無限制',
          value: '20',
        },
        {
          text: '３分 - 少部分需協助才能移動',
          value: '15',
        },
        {
          text: '２分 - 大部分需協助才能移動',
          value: '10',
        },
        {
          text: '１分 - 完全無法自行移動',
          value: '5',
        },
      ],
      nutritionOptions: [
        {
          text: '４分 - 總攝取量完整',
          value: '20',
        },
        {
          text: '３分 - 總攝取量>75%',
          value: '15',
        },
        {
          text: '２分 - 總攝取量介於50%-75%',
          value: '10',
        },
        {
          text: '１分 - 總攝取量<50%',
          value: '5',
        },
      ],
      frictionOptions: [
        {
          text: '３分 - 沒有明顯問題：可獨立在床上或椅子上移動',
          value: '15',
        },
        {
          text: '２分 - 有潛在問題：移動時需要輕微協助',
          value: '10',
        },
        {
          text: '１分 - 有此問題：移動時需要中度到極大的協助，完全抬起來而不與床單接觸是不可能的',
          value: '5',
        },
      ],
    }
  },
  methods: {
    clearForm() {
      this.form = {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        FEEL: '',
        WET: '',
        ACT: '',
        MOVE: '',
        NUTRITION: '',
        FRICTION: '',
      }
      this.$refs.form.resetValidation()
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        console.log(this.form)
        try {
          const response = await axios.post('/api/mohw_assessment/qc105', this.form)

          if (response.status === 200) {
            alert('資料成功上傳！')
            this.clearForm()
          } else {
            throw new Error('資料上傳失敗')
          }
        } catch (error) {
          console.log(`上傳失敗：${error.response?.data?.message || error.message}`)
        }
      }
    },
  },
}
</script>
