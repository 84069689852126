<template>
  <v-container>
    <v-form ref="form" v-model="isFormValid">
      <v-row>
        <!-- MAIN_ID -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.MAIN_ID"
            label="主檔案 ID"
            :rules="[rules.required, rules.numeric]"
            maxlength="22"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_DT -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_DT"
            label="評估日期"
            :rules="[rules.required, rules.dateFormat]"
            maxlength="8"
            placeholder="yyyyMMdd"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_USER -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_USER"
            readonly
            label="評估人員"
            :rules="[rules.required]"
            maxlength="200"
            required
          ></v-text-field>
        </v-col>
        <v-col class="pa-0" cols="12"> <v-divider /></v-col>

        <!-- REMARK -->
        <v-col cols="12">
          <v-textarea
            outlined
            v-model="form.REMARK"
            label="備註"
            :rules="[rules.optional]"
            maxlength="500"
          ></v-textarea>
        </v-col>

        <!-- Q1 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q1"
            :items="cognitionErrorOptions"
            label="今天是幾年月幾日？"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q2 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q2"
            :items="cognitionErrorOptions"
            label="今天是星期幾？"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q3 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q3"
            :items="cognitionErrorOptions"
            label="這裡是什麼地方？"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q4 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q4"
            :items="cognitionErrorOptions"
            label="你的電話號碼是幾號？"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q4_A -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q4_A"
            :items="cognitionErrorOptions"
            label="你住在什麼地方？"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q5 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q5"
            :items="cognitionErrorOptions"
            label="你幾歲了？"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q6 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q6"
            :items="cognitionErrorOptions"
            label="你的生日是哪一天？"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q7 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q7"
            :items="cognitionErrorOptions"
            label="現任總統是誰？"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q8 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q8"
            :items="cognitionErrorOptions"
            label="前任總統是誰？"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q9 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q9"
            :items="cognitionErrorOptions"
            label="你媽媽叫什麼名字？"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q10 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q10"
            :items="cognitionErrorOptions"
            label="從20減3開始，第一直減3減下去。"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- DEGREE -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.DEGREE"
            :items="cognitiveDegreeOptions"
            label="心智功能程度"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="pa-2" justify="center"
        ><v-btn color="primary" @click="submitForm"> 送出表單 </v-btn></v-row
      >
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      isFormValid: false,
      form: {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '郭姿君',
        REMARK: '',
        Q1: '',
        Q2: '',
        Q3: '',
        Q4: '',
        Q4_A: '',
        Q5: '',
        Q6: '',
        Q7: '',
        Q8: '',
        Q9: '',
        Q10: '',
        DEGREE: '',
      },
      rules: {
        required: (value) => !!value || '此欄位為必填項目',
        numeric: (value) => !isNaN(value) || '必須是數字',
        dateFormat: (value) => /\d{8}/.test(value) || '請輸入正確的日期格式 yyyyMMdd',
        optional: () => true,
      },
      cognitionErrorOptions: [
        { text: '對', value: '5' },
        { text: '錯', value: '10' },
      ],
      cognitiveDegreeOptions: [
        { text: '心智功能完好', value: '5' },
        { text: '輕度智力缺損', value: '10' },
        { text: '中度智力缺損', value: '15' },
        { text: '嚴重智力缺損', value: '20' },
      ],
    }
  },
  methods: {
    clearForm() {
      this.form = {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '郭姿君',
        REMARK: '',
        Q1: '',
        Q2: '',
        Q3: '',
        Q4: '',
        Q4_A: '',
        Q5: '',
        Q6: '',
        Q7: '',
        Q8: '',
        Q9: '',
        Q10: '',
        DEGREE: '',
      }
      this.$refs.form.resetValidation()
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        console.log(this.form)
        try {
          const response = await axios.post('/api/mohw_assessment/qc109', this.form)

          if (response.status === 200) {
            alert('資料成功上傳！')
            this.clearForm()
          } else {
            throw new Error('資料上傳失敗')
          }
        } catch (error) {
          console.log(`上傳失敗：${error.response?.data?.message || error.message}`)
        }
      }
    },
  },
}
</script>
