<template>
  <v-container>
    <v-form ref="form" v-model="isFormValid">
      <v-row>
        <!-- MAIN_ID -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.MAIN_ID"
            label="主檔案 ID"
            :rules="[rules.required, rules.numeric]"
            maxlength="22"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_DT -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_DT"
            label="評估日期"
            :rules="[rules.required, rules.dateFormat]"
            maxlength="8"
            placeholder="yyyyMMdd"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_USER -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_USER"
            readonly
            label="評估人員"
            :rules="[rules.required]"
            maxlength="200"
            required
          ></v-text-field>
        </v-col>
        <v-col class="pa-0" cols="12"> <v-divider /></v-col>

        <!-- Q1 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q1"
            :items="healthScaleOptions"
            label="睡眠困難，譬如難以入睡或易醒或早醒"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q2 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q2"
            :items="healthScaleOptions"
            label="感覺緊張或不安"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q3 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q3"
            :items="healthScaleOptions"
            label="覺得容易煩躁或易怒"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q4 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q4"
            :items="healthScaleOptions"
            label="感覺憂鬱、心情低落"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q5 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q5"
            :items="healthScaleOptions"
            label="覺得比不上別人"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q6 -->
        <v-col cols="12" md="4">
          <v-select
            outlined
            v-model="form.Q6"
            :items="healthScaleOptions"
            label="自殺想法"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="pa-2" justify="center"
        ><v-btn color="primary" @click="submitForm"> 送出表單 </v-btn></v-row
      >
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      isFormValid: false,
      form: {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '郭姿君',
        Q1: '',
        Q2: '',
        Q3: '',
        Q4: '',
        Q5: '',
        Q6: '',
      },
      rules: {
        required: (value) => !!value || '此欄位為必填項目',
        numeric: (value) => !isNaN(value) || '必須是數字',
        dateFormat: (value) => /\d{8}/.test(value) || '請輸入正確的日期格式 yyyyMMdd',
      },
      healthScaleOptions: [
        { text: '完全沒有', value: '5' },
        { text: '輕微', value: '10' },
        { text: '中等程度', value: '15' },
        { text: '嚴重', value: '20' },
        { text: '非常嚴重', value: '25' },
      ],
    }
  },
  methods: {
    clearForm() {
      this.form = {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '郭姿君',
        Q1: '',
        Q2: '',
        Q3: '',
        Q4: '',
        Q5: '',
        Q6: '',
      }
      this.$refs.form.resetValidation()
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        console.log(this.form)
        try {
          const response = await axios.post('/api/mohw_assessment/qc110', this.form)

          if (response.status === 200) {
            alert('資料成功上傳！')
            this.clearForm()
          } else {
            throw new Error('資料上傳失敗')
          }
        } catch (error) {
          console.log(`上傳失敗：${error.response?.data?.message || error.message}`)
        }
      }
    },
  },
}
</script>
